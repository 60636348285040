import { axios } from '@/utils/request'

export function getCumsBusinsessPage(type, parameter) {
  return axios({
      url: `cums/register/${type}/list_clear`,
      method: 'get',
      params: parameter
  })
}

export function getCumsImportPage(type, parameter) {
  return axios({
      url: `cums/register/${type}/list_import`,
      method: 'get',
      params: parameter
  })
}

export function getCumsBusinsessDetail(type, parameter) {
  return axios({
      url: `cums/register/${type}/detail`,
      method: 'get',
      params: parameter
  })
}

export function saveCumsBusinessInfo(type, data) {
  return axios({
      url: `cums/register/${type}/save`,
      method: 'post',
      data
  })
}

export function deleteCumsBusinessInfo(type, data) {
  return axios({
      url: `cums/register/${type}/remove`,
      method: 'post',
      data
  })
}

export function storeCumsBusinessInfo(type, data) {
  return axios({
    url: `cums/register/${type}/store`,
    method: 'post',
    data
  })
}

export function getListImportGoods(params) {
  return axios({
      url: `cums/register/cums_import/list_import_good`,
      method: 'get',
      params
  })
}

export function getListClearGoods(params) {
  return axios({
      url: `cums/register/cums_clear/list_clear_good`,
      method: 'get',
      params
  })
}

export function getNumOptions(type, params) {
  return axios({
      url: `cums/register/${type}/option`,
      method: 'get',
      params
  })
}

export function postCumsStaticsPage(type, data) {
  return axios({
      url: `cums/statics/${type}`,
      method: 'post',
      data
  })
}

export function getCumsStaticsPage(type, params) {
  return axios({
      url: `cums/statics/${type}`,
      method: 'get',
      params
  })
}

export function listStorage(data) {
  return axios({
    url: 'cums/statics/list_storage',
    method: 'post',
    data
  })
}

export function exportListStorage(data) {
  return axios({
    url: 'cums/statics/export_storage_list',
    method: 'post',
    data
  })
}

export function exportCumsReport(type, params) {
  return axios({
      url: `cums/statics/${type}`,
      method: 'get',
      params
  })
}

export function getCumsMetaOption(type, parameter) {
  return axios({
    url: `cums/meta/${type}/option`,
    method: 'get',
    params: parameter
  })
}

export function getCumsMetaPage(type, parameter) {
  return axios({
    url: `cums/meta/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function getCumsMetaCompanyPage(type, parameter) {
  return axios({
    url: `cums/meta/${type}/page`,
    method: 'get',
    params: parameter
  })
}

export function createCumsMetaInfo(type, data) {
    return axios({
      url: `cums/meta/${type}/create`,
      method: 'post',
      data
    })
}

export function saveCumsMetaInfo(type, data) {
  return axios({
    url: `cums/meta/${type}/save`,
    method: 'post',
    data
  })
}

export function updateCumsMetaInfo(type, data) {
    return axios({
        url: `cums/meta/${type}/update`,
        method: 'post',
        data
    })
}

export function deleteCumsMetaInfo(type, data) {
  return axios({
      url: `cums/meta/${type}/delete`,
      method: 'post',
      data
  })
}

export function getCumsFinancePage(type, data) {
  return axios({
    url: `cums/finance/${type}`,
    method: 'post',
    data
  })
}

export function CumsFinanceFunc(type, data) {
  return axios({
    url: `cums/finance/${type}`,
    method: 'post',
    data
  })
}

export function exportCumsFinance(type, parameter) {
  return axios({
    url: `cums/finance/${type}`,
    method: 'get',
    params: parameter
  })
}

export function getCumsPayQrcode(data) {
  return axios({
    url: `cums/finance/qrcode`,
    method: 'post',
    data
  })
}

// 导出客服部进库、清关委托书 出库导出核注清单号
export function exportCmsLetter(type, parameter) {
  return axios({
    url: `cums/register/${type}/export_data`,
    method: 'get',
    params: parameter
  })
}

// 导出进库、清关货物信息
export function exportCmsGoodsInfo(type, parameter) {
  return axios({
    url: `cums/register/${type}/export_good`,
    method: 'get',
    params: parameter
  })
}

// 根据类型查询客服部基础信息
export function getListByType(type) {
  return axios({
    url: `/cums/metaInfo/getList/` + type,
    method: 'get'
  })
}

export function getCumsBalanceFunc(type, params) {
  return axios({
    url: `/cums/balance/${type}`,
    method: 'post',
    data: params
  })
}

export function getCumsBalanceFeeFunc(type, params) {
  return axios({
    url: `/cums/balance/${type}`,
    method: 'get',
    params
  })
}

export function queryUnbalanceFees(data) {
  return axios({
    url: `/cums/balance/queryUnbalanceFees`,
    method: 'post',
    data: data
  })
}

export function addFeesToBalance(data) {
  return axios({
    url: `/cums/balance/addFeesToBalance`,
    method: 'post',
    data: data
  })
}

export function getCumsPayBillFunc(type, params) {
  return axios({
    url: `/cums/payment/${type}`,
    method: 'post',
    data: params
  })
}

export function getBalanceDetailByOrderId(params) {
  return axios({
    url: `cums/finance/getBalanceInfoByOrderId`,
    method: 'get',
    params
  })
}

export function getPaymentDetailByOrderId(params) {
  return axios({
    url: `cums/finance/getPaymentInfoByOrderId`,
    method: 'get',
    params
  })
}

// 导出客户收益分析表
export function exportCustomerProfitSheet(params) {
  return axios({
    url: `cums/statics/export_customer_profit_sheet`,
    method: 'get',
    params
  })
}

// 导出客户开票收益分析表
export function exportInvoiceCustomerProfitSheet(params) {
  return axios({
    url: `cums/statics/export_invoice_customer_profit_sheet`,
    method: 'get',
    params
  })
}

export function listPageCumsOffice(data) {
  return axios({
    url: `cums/register/cums_office/listPage`,
    method: 'post',
    data
  })
}

export function saveOrUpdateCumsOffice(data) {
  return axios({
    url: `cums/register/cums_office/saveOrUpdate`,
    method: 'post',
    data
  })
}

export function getCumsOfficeDetail(id) {
  return axios({
    url: `cums/register/cums_office/detail/${id}`,
    method: 'get'
  })
}

export function deleteCumsOffice(data) {
  return axios({
    url: `cums/register/cums_office/delete`,
    method: 'post',
    data
  })
}

export function detailBySerialNum(params) {
  return axios({
    url: `cums/register/cums_clear/detailBySerialNum`,
    method: 'get',
    params
  })
}

export function importGoodsDetail(data) {
  return axios({
      url: `cums/register/cums_import/importGoodsDetail`,
      method: 'post',
      data
  })
}

export function initCumsAdjust() {
  return axios({
    url: `cums/adjust-order/init`,
    method: 'get'
  })
}

export function pageListAdjust(data) {
  return axios({
    url: `cums/adjust-order/pageList`,
    method: 'post',
    data
  })
}

export function submitApplyAdjust(data) {
  return axios({
    url: `cums/adjust-order/submitApply`,
    method: 'post',
    data
  })
}

export function deleteAdjustOrder(data) {
  return axios({
    url: `cums/adjust-order/deleteAdjustOrder`,
    method: 'post',
    data
  })
}

export function saveAdjustOrder(data) {
  return axios({
    url: `cums/adjust-order/saveAdjust`,
    method: 'post',
    data
  })
}

export function getImportDetailBySerialNo(data) {
  return axios({
    url: `cums/register/cums_import/getDetailBySerialNo`,
    method: 'get',
    params: data
  })
}

export function getClearDetailBySerialNo(data) {
  return axios({
    url: `cums/register/cums_clear/getDetailBySerialNo`,
    method: 'get',
    params: data
  })
}
